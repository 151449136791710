import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { STATUS } from 'config/constants/launchpad/type';
import { actionDetailLaunchpad, actionStepsAccount, actionTagTypeProject, actionTransactionHashLaunchpad, getPublicPoolLaunchpad, getStatusLoading, getVolume, getATH, getStatusLoadingForDetail } from './actions';
import { ActionTagTypeProjectType, AthItem, DataUserByLaunchpad, LauchPads, StepsAccountType, Volume } from './type';

interface InitialStateType {
    typeProject: string;
    launchPads: DataUserByLaunchpad[],
    isLoading: boolean,
    detailLaunchpad: DataUserByLaunchpad,
    transactionHashLaunchpad: string,
    stepsAccount: StepsAccountType,
    volume: {
        data: string,
        error: boolean
    },
    ath: {
        data: AthItem[],
        error: boolean,
    },
    isLoadingDetail: boolean,
}
const initialState: InitialStateType = {
    typeProject: STATUS.UPCOMING,
    launchPads: [],
    isLoading: true,
    detailLaunchpad: {} as DataUserByLaunchpad,
    transactionHashLaunchpad: "",
    stepsAccount: null,
    volume: {
        data: "0",
        error: false
    },
    ath: {
        data: [],
        error: false
    },
    isLoadingDetail: false,
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(actionTagTypeProject, (state: InitialStateType, action: PayloadAction<ActionTagTypeProjectType>) => {
            state.typeProject = action.payload.type;
        })
        .addCase(actionStepsAccount, (state: InitialStateType, action: PayloadAction<any>) => {
            state.stepsAccount = action.payload;
        })
        .addCase(actionTransactionHashLaunchpad, (state: InitialStateType, action: PayloadAction<{ message: string }>) => {
            state.transactionHashLaunchpad = action.payload.message
        })
        .addCase(actionDetailLaunchpad, (state, action) => {
            state.detailLaunchpad = action.payload;
        })
        .addCase(getPublicPoolLaunchpad, (state: InitialStateType, action: PayloadAction<LauchPads>) => {
            state.launchPads = action.payload.launchPads
        })
        .addCase(getStatusLoading, (state: InitialStateType, action: PayloadAction<{ isLoading: boolean }>) => {
            state.isLoading = action.payload.isLoading;
        })
        .addCase(getVolume, (state: InitialStateType, action: PayloadAction<Volume>) => {
            state.volume = action.payload.volume
        })
        .addCase(getATH, (state, action) => {
            state.ath = action.payload.ath
        })
        .addCase(getStatusLoadingForDetail, (state, action) => {
            state.isLoadingDetail = action.payload.isLoadingDetail
        })
);
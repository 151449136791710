import Link from "next/link";
import styled from "styled-components"

export const BuyCloud = () => {
    return (
        <CsBuyCloud>
            <Link href="/swap">
                Buy Cloud
            </Link>
        </CsBuyCloud>
    )
};

const CsBuyCloud = styled.div`
    margin-right: 10px;
    border-radius: 28px;
    border: 2px solid #FFF;
    height: 46px;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    padding: 11px 26px;
    font-style: normal;
    font-weight: 600;
    display:flex;
    justify-content: center;
    align-items: center;
    /* @media screen and (max-width: 600px){
        padding: 13px 14px;
        font-size: 12px;
    } */
    @media screen and (max-width: 768px){
    height: 36px;
    font-size: 10px;
    padding:8px;
    /* margin:0 */
  }
`

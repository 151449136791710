import { ContextApi } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
    EarnFilledIcon,
    EarnFillIcon,
    InfoMenuFillIcon, InfoMenuIcon,
    MenuItemsType, MoreIcon, NftFilledIcon, NftFillIcon, TradeFilledIcon, TradeIcon
} from '@pancakeswap/uikit'
import { DropdownMenuItems, DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_CHAIN_CLOUDBASE, SUPPORT_CHAIN_OP } from 'utils/wagmi'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        [
            {
                label: t('Trade'),
                icon: TradeIcon,
                fillIcon: TradeFilledIcon,
                href: '/swap',
                items: [
                    {
                        label: t('Swap'),
                        href: '/swap',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE,
                    },
                    {
                        label: t('Liquidity'),
                        href: '/liquidity',
                    },
                    {
                        label: t('Bridge'),
                        href: 'https://bridge.base.org',
                        type: DropdownMenuItemType.EXTERNAL_LINK,
                        isActive: true,
                    },
                    {
                        label: t('Perpetual'),
                        href: '#',
                        disabled: true,
                    },
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('Games'),
                icon: NftFillIcon,
                fillIcon: NftFilledIcon,
                href: '/battle',
                showItemsOnMobile: false,
                items: [
                    {
                        label: t('CloudMan Battle'),
                        href: '/battle',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE,
                        status: { text: t('Beta'), color: 'success' },
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('Earn'),
                icon: EarnFillIcon,
                fillIcon: EarnFilledIcon,
                href: '/farms',
                items: [
                    {
                        label: t('Farms'),
                        href: '/farms',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE
                    },
                    {
                        label: t('Pools'),
                        href: '/pools',
                        supportChainIds: [ChainId.BASE_MAINNET]
                    },
                    {
                        label: t('Tianlong Pool'),
                        href: '/tianlongpool',
                        supportChainIds: SUPPORT_CHAIN_OP,
                    },
                    {
                        label: t('Dragon Pool'),
                        href: '/dragonpool',
                        supportChainIds: SUPPORT_CHAIN_OP
                    },
                    {
                        label: t('BaseNS Cloud Pool'),
                        href: '/basenscloudpool',
                        supportChainIds: SUPPORT_CHAIN_OP
                    },
                    {

                        label: t('Base Connector Pool'),
                        href: '/feedpool',
                        supportChainIds: SUPPORT_CHAIN_OP
                    },
                    // {
                    //     label: t('Tianlong Upgrade'),
                    //     href: '/tianlongupgrade',
                    //     supportChainIds: SUPPORT_CHAIN_OP
                    // }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('Bridge'),
                icon: TradeIcon,
                fillIcon: TradeFilledIcon,
                href: 'https://bridge.cloudbase.finance',
                items: [
                    {
                        label: t('Base Bridge'),
                        href: 'https://bridge.base.org',
                        type: DropdownMenuItemType.EXTERNAL_LINK,
                        isActive: true,
                    },
                    {
                        label: t('Axelar Bridge'),
                        href: 'https://bridge.cloudbase.finance',
                        type: DropdownMenuItemType.EXTERNAL_LINK,
                        isActive: true,
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            // {
            //     label: t('Info'),
            //     icon: InfoMenuIcon,
            //     fillIcon: InfoMenuFillIcon,
            //     href: '/info',
            //     showItemsOnMobile: false,
            //     items:
            //      [
            //       {
            //         label: t('Info'),
            //         href: '/info',
            //         supportChainIds:[ ChainId?.BASE_GOERLI ]
            //       }
            //     ].map((item) => addMenuItemSupported(item, chainId))
            //   },
            {
                label: '',
                icon: MoreIcon,
                hideSubNav: true,
                // disabled: true,
                items: [
                    {
                        label: t('Pre-Sale'),
                        href: '/presale',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE
                    },
                    {
                        label: t('LaunchPad'),
                        href: '/launchpad',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE
                    },
                    {
                        label: t('Airdrop'),
                        href: '/airdrop',
                    },
                    {
                        label: t('NFT MarketPlace'),
                        href: '#',
                        disabled: true,
                    },
                    {
                        label: t('AMM NFT'),
                        href: '#',
                        disabled: true,
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
              },

        ].map((item) => addMenuItemSupported(item, chainId))

export default config

import { multicallAddresses } from '@pancakeswap/multicall'

export default {
    masterChef: {
        97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
        56: '0x827d6eb00efb26a4d815706771dfde2abc84c451',
        1945: '0xfC6412C60db06DE222538d51f0585933153dB9a5',
        1975: '0xe5485c394D8d1Ce80d0D648a4c6DC9c749dda7cb',
        84531: '0x0c6F2bCD7d53829afa422b4535c8892B1566E8c5',
        8453: '0x12cd60d2aBDa4E032fF41Ce6Db75AEd39D848C74',
    },
    masterChefV1: {
        97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
        56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
        84531: '0x0c6F2bCD7d53829afa422b4535c8892B1566E8c5',
    },
    multiCall: multicallAddresses,
    cakeVault: {
        56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
        97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    },
    cakeFlexibleSideVault: {
        56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
        97: '',
    },
    bunnySpecialXmas: {
        56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
        97: '',
    },

    AnniversaryAchievement: {
        56: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
        97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    },

    nftSale: {
        56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
        97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    },

    // TODO: multi
    zap: {
        56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
        97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    },
    iCake: {
        56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
        97: '',
    },
    bCakeFarmBooster: {
        56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
        97: '',
        84531: ''
    },
    bCakeFarmBoosterProxyFactory: {
        56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
        97: '',
    },
    nonBscVault: {
        56: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841', // Only for pass contracts test
        1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
        5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    },
    crossFarmingSender: {
        56: '0x327d26dE30f92600620A99043034e0A5FD9402C8', // Only for pass contracts test
        1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
        5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    },
    crossFarmingReceiver: {
        56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
        97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    },
    votingProposals: {
        97: "0xfca9151751cc0210db5bc310ba6530715b6e04e5",
        56: "0xac9ae9ff776b36af7746d2a759db25f04ef311ab"
    },
    poolRun: {
        97: '0x2cb575f98cf1900061df48666b46aa1341aa6db4',
        56: '0x9af4e852079af921ae63b57d66e65581b239b244',
        1945: '0x05693C7222E185311b90416b447920CEaDcD5583'
    },
    runMarketplace: {
        97: '0x65E9Daeb177E0096F4fca795683D18143E58Bafd',
        56: '0x36e75C5a3efFb2945785AeD307AA12E5dea51cF3'
    },
    runMarketplaceMultiByBox: {
        97: '0xdc9f52cc9bdd1ffcfd9292dbd9dd844047234d0f',
        56: ''
    },
    withdrawNftRun: {
        97: '0xd73f571EaC89026d9AEC4921fc1338610A1af491',
        56: '0xcE6bDB23F87804ACd99Fd6E06cF7EF91F6331bd5'
    },
    poolProposals1: {
        97: '0x2cb83a47f716b086dab25cce88f064e9305938d9',
        56: '0xbc3150a7bffdc8caee910a353ce3b64410e2b964'
    },
    poolStore1: {
        97: '0x0211eca9f5076f20243e7c396f05e87df6ba889c',
        56: '0xdf168fbfba1ac83b4a0873fc43d80e71b4e4479f'
    },
    poolProposals2: {
        97: '0x4eb52d6d798813ac3a4fb085688f79292dbbc59a',
        56: '0xf0cf8ab8320984c4ebf9c43c859153cabacb144b'
    },
    poolStore2: {
        97: '0xb8dc7826a15fa24d5d7a995e22f7ddbfada7e697',
        56: '0x3a98833b20dee5fa22dfb77d1f30ae8ef63c9d05'
    },
    mysteryBox: {
        97: '0xd6e7b57b58c9fa1bf534e8c899a19ea523b4fd85',
        56: '',
        1945: '0x134261aCCF115A2525E1E8e1981fffE0b44a690d',
        1975: '0x1AFF690C0B63cce5682B37b243373A61a60F4117',
        10001: '0x9b83bb2f49491210770daed4951c601f2f6e8fb0'
    },
    dragonRissing: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xeF8C67eBC1C881898cf92794B1E327f252C2D227',
        10: '0xE296fc1770D0a94398273935DE8551Edf3425D04'
    },
    dragonNftPool: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x33A47AdEb4c19AB2F4b6aa111992550E122B4822',
        10: '0x2cEB955550529B89076C0d19F5D94718e19CA3c1'
    },
    baseNsCloud: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xeF8C67eBC1C881898cf92794B1E327f252C2D227',
        10: '0x4ff12f683f05f4e941b58d2c7cca07ff3129650f'
    },
    BaseNftPool: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x33A47AdEb4c19AB2F4b6aa111992550E122B4822',
        10: '0x1Ee9Af9cF41970903A33d7D60c55cd435DC4f27E'
    },
    baseConnector: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x6625793dEbdC4A1464E07bccC90f536053A42F20',
        10: '0x0D42d13e3E2f97b1589525Cd2f288cAAC79593Ae'
    },
    baseLuckyNft: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x4479aac2664E6F811a6E28c9952af53FC2B3fdE6',
        10: '0xecD9C8247820FF1255f1f7e7db48273747633dA2'
    },
    CloudFusion: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x9E2621e719288b93C220c4F6696A4384c4136Dee',
        10: '0x7cB8d57f72b1FA2571b4C7788eBeD493dA1Ad78f'
    },
    FeedNftPool: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x33A47AdEb4c19AB2F4b6aa111992550E122B4822',
        10: '0x11934E6f4bDA0EcECAD936AA7a6Eb708133B6637'
    },
    BaseConnectorNft: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x33A47AdEb4c19AB2F4b6aa111992550E122B4822',
        10: '0x0d42d13e3e2f97b1589525cd2f288caac79593ae'
    },
    tianlongNftPool: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x115066a4CCCCc42055147F94C38126A54f2F5fda',
        8453: '0xE7103da0e383EEDf7713a06A7C9269c0BfEE4e82',
        10: '0x02346855C03253e320604700BB1a6F2E06D94Cf9'
    },
    cloudianNFT: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x2849CDD0e333C3089b03399d08F97C3Ac336A07e',
        8453: '0xE7103da0e383EEDf7713a06A7C9269c0BfEE4e82',
        10: '0x02346855C03253e320604700BB1a6F2E06D94Cf9'
    },
    // for pre sale
    presale: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xdb305C860cb3A34272DE150a7e88013Df5ed3211',
        10: '',
        8453: "0x11934E6f4bDA0EcECAD936AA7a6Eb708133B6637"
    },
    wolfLabIdo: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '',// 0x34Ecf586929e7Adb0BFeF45b0a0484F0203fe591
        10: '',
        8453: "0xb246F67172fccafaB6037a7A2172510E076e4C6d"
    },
    stakeTokenForCloud: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xEca25D3599C14fe1f495edb0D6d463065B0A598d',
        10: ''
    },
    stakeNftForCloud: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xa95799e049876E6e9bd15973AC5E257A912BF18A',
        10: ''
    },
    cloudLaunchPad: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xf691deF5030B9bD09FAF017Ab5d248CD04785abe',
        10: ''
    },

    contractLaunchPad: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xEBB3e2Fd16630aF36651E94266de26328899E5E4',
        10: ''
    },
    contractRegister: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x2944e594654fbeD63C4faEfA0c830272EE6Ee3CB',
        10: ''
    },
    contractLaunchPad2: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x3fd82b2adCE23A349Bd12B55E2B2Ce050f16E563',
        10: ''
    },
    contractRegister2: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x093eEF1f471958CD150Cda2d4FC8E574AFFE735d',
        10: ''
    },
    contractLaunchPad3: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x27f12A62B9AF1CAb37A05c10aBFC97B5A9bDbA45',
        10: ''
    },
    contractRegister3: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x36c0aD88bD2D03B2c9D2f0eB148492cD84bfC1cb',
        10: ''
    },
    contractLaunchPad4: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x5594c3e1c47802a965CdBbC3655Cc8F53f40917F',
        10: ''
    },
    contractRegister4: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xcc2501c71f0731Cbe0b543B09EFE09469cDa1DF1',
        10: ''
    },
    contractLaunchPad5: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x5E905Aa6e7fA30499d46436BD0C78E81D4919370',
        10: ''
    },
    contractRegister5: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x8ca0c50DBD26E4E74D213FEBF2EBC11d96b24A4d',
        10: ''
    },
    contractLaunchPad6: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x519b66E9790Dd1DD075477D27E7970C8682e94Ed',
        10: ''
    },
    contractRegister6: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x7BaE9D975cc201b27D0BaB7BeFA6286351AF753C',
        10: ''
    },
    contractLaunchPad7: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x681dD99b879EEc2bA424411F3f3E0c14359dc09c',
        10: ''
    },
    contractRegister7: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x53838932664012A7F5A68B199C3d511213304144',
        10: ''
    },
    contractLaunchPad8: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x6aBb113946a28d7f855E01F6a0261cFFdbd013A8',
        10: ''
    },
    contractRegister8: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0xBe964580E5FA5bA858c0D71bd1A32071Ad78c20B',
        10: ''
    },

    // main net
    contractLaunchPadCloudBase: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x36848BDb6D0397a9D0ebaf1D544c095ca374Ca7e',
        8453: '0x0DB2a71D11287A82198bDdF1fE6974458Ad49446',
        10: ''
    },
    contractRegisterCloudBase: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x96282A7340b9Ca0061C5C6CDe93F0d76F13BeE40',
        8453: '0x7CbBfEb9E73254bC4a0ec56b6B760c4Db2D8031D',
        10: ''
    }, 
    cloudManBattleBeta: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x36848BDb6D0397a9D0ebaf1D544c095ca374Ca7e',
        8453: '0xb50e1a026416519010a63319045b6fd5c2ff507d',
        10: ''
    },
    airdrop: {
        97: '',
        56: '',
        1945: '',
        1975: '',
        10001: '',
        84531: '0x573cCEa60bcba5aE18591e2791658363dc680606',
        8453: '0xa38A67015A092fd64ead123c9b24ff6BBCf3a1ea',
        10: ''
    },
}

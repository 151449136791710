import { createAction } from '@reduxjs/toolkit';
import { AthItem, DataUserByLaunchpad, LauchPads, StepsAccountType, Volume } from './type';

export const actionTagTypeProject = createAction<{ type: string }>("launchpad/actionTagProject");
export const actionStepsAccount = createAction<StepsAccountType>("launchpad/actionStepsAccount");
export const actionTransactionHashLaunchpad = createAction<{ message: string }>("launchpad/actionTransactionHashLaunchpad");
export const actionDetailLaunchpad = createAction<DataUserByLaunchpad>("launchpad/actionDetailLaunchpad");
export const getStatusLoading = createAction<{ isLoading: boolean }>("launchpad/getStatusLoading");

export const getPublicPoolLaunchpad = createAction<LauchPads>("launchpad/getPublicPoolLaunchpad");
export const getVolume = createAction<Volume>("launchpad/getVolume");
export const getATH = createAction<{ath: {data: AthItem[], error: boolean}}>("launchpad/getATH");

export const getStatusLoadingForDetail = createAction<{isLoadingDetail: boolean}>("launchpad/getStatusLoadingForDetail");


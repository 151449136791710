import { BaseCurrency, Currency, SerializedToken, Token } from "@pancakeswap/sdk";
import { ElementType } from "react";
import { Address } from "../types";

export interface socialItem {
    name: string,
    href: string,
    icon: ElementType<any>
}

export interface tierItem {
    minAllocation: number, // cloud
    maxAllocation: number, // cloud
    stakeAmount: number,
    lockDays: number,
    tier: TierItem,
    minAllocationUsd?: number,
    maxAllocationUsd?: number,
}

export interface nftStakeItem {
    nftAddress: Address,
    nftName: string
}

export interface launchPadItem {
    id: number,
    isNativePool: boolean,
    projectName: string,
    projectImage: string,
    description: string,
    tokenBuy: Currency | Token,
    tokenEarn: Token,
    tokenStake: Token,
    listedDate: string,
    tokenAddLP: Currency | Token,
    socials: socialItem[],
    priceAtAddLP: string,
    vestingRound: number,
    tokenListing: string,
    initialSupply: string,
    isDisabledNFT?: boolean,
    maxAllocationOverFlow?: number, // cloud
    distribution: {
        description: string,
        titleHref: string,
        href: string,
    },
    vestingDescription: string,
    contractLaunchPad: Address,
    contractRegister: Address,
    register: {
        registerWithNFT?: {
            minAllocation: number, // cloud
            maxAllocation: number, // cloud
            lockDays: number,
            tier: TierItem,
            nftName: string,
            nftAddress: Address,
            minAllocationUsd?: number,
            maxAllocationUsd?: number,
        }[],
        registerWithTier?: tierItem[]
    }
}

export interface TierItem {
    tierName: string,
    tierType: number
}

export const TIER_TYPE = {
    NFT: "NFT",
    TOKEN: "TOKEN"
}

export const STATUS = {
    UPCOMING: "Upcoming",
    LIVE: "Live",
    ENDED: "Ended",
    LISTED: "Listed",
    ENDED_NFT: "ENDED_NFT",
    NULL: "NULL",
}

export const STEPS = {
    REGISTER: "REGISTER",
    PURCHASE: "PURCHASE",
    CLAIM: "CLAIM",
}
export const MAPING_SELECT_BY_TIER = {
    1: {
        tierNumber: 1,
        listValue: [10, 20, 30, 40]
    },
    2: {
        tierNumber: 2,
        listValue: [50, 70, 90, 100]
    },
    3: {
        tierNumber: 3,
        listValue: [100, 120, 140, 150]
    },
    4: {
        tierNumber: 4,
        listValue: [150, 170, 190, 200]
    },
    5: {
        tierNumber: 5,
        listValue: [50, 100, 150, 200]
    },
}